import React, { Component } from 'react'

class Collapsebuttons extends Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.state = {
          collapsed: true,
        };
      }
      
      handleClick() {
        console.log('Click happened');
        const collapsed = this.state.collapsed;
        this.setState({ collapsed: !collapsed });
      }

    render () {
        const {children} = this.props;

        return (
            <div className={this.state.collapsed ? 'codeblock collapsed': 'codeblock'}>
                <svg xmlns="http://www.w3.org/2000/svg" width="54" height="14" viewBox="0 0 54 14">
                    <g fill="none" fillRule="evenodd" transform="translate(1 1)">
                        <circle cx="6" cy="6" r="6" fill="#FF5F56" stroke="#E0443E" strokeWidth=".5"></circle>
                        <circle cx="26" cy="6" r="6" fill="#FFBD2E" stroke="#DEA123" strokeWidth=".5" className="minimize" onClick={this.handleClick}></circle>
                        <circle cx="46" cy="6" r="6" fill="#27C93F" stroke="#1AAB29" strokeWidth=".5"></circle>
                        </g>
                </svg>
                {children}
            </div>
        );
    }

};

// export {collapsed};
export default Collapsebuttons